//import React from "react";
import { Box} from "@mui/material";
import "./Loader.css";

const Loader = ({ isResponsive = false }) => {
  //const theme = useTheme();
  return (
    <Box
      sx={{
        height: `${isResponsive ? "100%" : "100vh"}`,
        width: `${isResponsive ? "100%" : "100vw"}`,
        background: "#00000008",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <span className="loader"></span>
    </Box>
  );
};

export default Loader;
