import Typography from "@mui/material/Typography";
import { useState } from "react";
import EnhancedTable from "../../components/common/custom-table/CustomTable";
import {  USER_ROLE } from "../../utils/constants";
import AddIcon from "@mui/icons-material/Add";
import AddUser from "../../components/addUser/AddUser";
import { userService } from "../../service";
import { IUser } from "../../interfaces";
import SubTopBar from "../../components/template/SubTopBar";
import Loader from "../../components/Loader";
import { Box } from "@mui/material";

const Users = () => {
  const [userCreation, setUserCreation] = useState(false);
  const [usersData, setUsersData] = useState<IUser[]>([]);
  const [loading, setLoading] = useState(false);
  //const [editDisabled, setEditDisabled] = useState(true);
  //const { role } = validateToken();

  // const toggleEditDisabled = (val: any) => {
  //   setEditDisabled(val);
  // };

  // useEffect(() => {
  //   fetchUsers();
  // }, []);

  const fetchUsers = async () => {
    //toggleEditDisabled(true);
    setLoading(true);
    const res = await userService.getUsers();
    setLoading(false);
    if (!res.status || !res.data) {
      return;
    }
    const filteredUsers = res.data.filter(user => user.role === 'super_admin');

    setUsersData(filteredUsers);
  };

  const handleSearch = (search: string) => {
     
  };
  
  return (
    <>
      {userCreation ? (
        <AddUser
          cancel={() => {
            setUserCreation(false);
          }}
          refresh={() => {
            fetchUsers();
          }}
          title="User"
          role={USER_ROLE}
        />
      ) : (
        <>
        {/* {editDisabled ? ( */}
          <>
            
              <SubTopBar
                title="Users"
                buttonText="Add User"
                buttonIcon={<AddIcon />}
                onButtonClick={() => {
                  setUserCreation(true);
                }}
                onSearch={handleSearch}
              />
           
              <SubTopBar title="Users" />
            
          </>
       
          <Box
            sx={{
              height: "calc(100% - 125px)",
              paddingBottom: "32px",
              paddingRight: "32px",
            }}
          >
            {loading ? (
              <Loader isResponsive />
            ) : (
              <>
                {usersData?.length ? (
                  <EnhancedTable
                    tableData={usersData}
                    refresh={fetchUsers}
                    role={USER_ROLE}
                    //toggleEditDisabled={toggleEditDisabled}
                  />
                ) : (
                  <Typography>No data</Typography>
                )}
              </>
            )}
          </Box>
        </>
      )}
    </>
  );
};

export default Users;
