import { Grid, TextField, Typography, useTheme, Select,MenuItem } from "@mui/material";
import { Box } from "@mui/system";
// import { useSnackbar } from "notistack";
import Avatar from "@mui/material/Avatar";
import { FC, useEffect, useRef, useState } from "react";
import { BiChevronRight, BiUser } from "react-icons/bi";
import { useLocation } from "react-router-dom";
import { getTitle, USER_ROLE } from "../../utils/constants";
import CustomButton from "../common/custom-buttons/CustomButton";
import { IUser } from "../../interfaces";

let file: any = null;

interface IProps {
  title?: string;
  role?: string;
  isEdit?: boolean;
  user?: IUser | null;
  cancel?: VoidFunction;
  refresh?: VoidFunction;
}

const AddUser: FC<IProps> = ({
  cancel = () => {},
  refresh = () => {},
  title = "User",
  role = USER_ROLE,
  isEdit = false,
  user,
}) => {
  const theme = useTheme();
  const location = useLocation();
  // const { enqueueSnackbar: snackbar } = useSnackbar();
  const [formValues, setFormValues] = useState<any>({});
  const [isFormValid, setFormValid] = useState(false);
  const [avatar, setAvatar] = useState<any>(null);
  const [submitting, setSubmitting] = useState(false);
  const [invalidPassword, setInvalidPassword] = useState(false);
  const fileInputRef = useRef<HTMLInputElement>(null);
  //const [selectedRole, setSelectedRole] = useState(role); // Set the initial value to 'role' or any default value you want.
  const [status, setStatus] = useState('')
  const handleFileSelected = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files || !event.target.files.length) {
      return;
    }
    file = event.target.files[0];
    setAvatar(URL.createObjectURL(file));
  };

  const handleFileButtonClick = () => {
    fileInputRef.current?.click();
  };

  const validatePassword = (password) => {
    const re = new RegExp(
      "^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[!@_#$%^&*])[a-zA-Z0-9!@_#$%^&*]{8,}$"
    );
    const isValidPassword = re.test(password);
    if (!isValidPassword) {
      setInvalidPassword(true);
      return;
    }

    setInvalidPassword(false);
  };

  const handleSubmit = () => {
    if (invalidPassword) {
      return;
    }
    
    setSubmitting(true);
    const data = new FormData();
    data.append("name", formValues.name);
    data.append("mobile", formValues.mobile);
    data.append("email", formValues.email);
    data.append("role", role);
    data.append("userImage", file);
    data.append("password", formValues.password);

    // if (isEdit && user) {
    //   userService.updateUser(data, user._id).then((res) => {
    //     setSubmitting(false);
    //     if (!res.status) {
    //       snackbar("User update failed", { variant: "error" });
    //       return;
    //     }

    //     snackbar("User updated", { variant: "success" });
    //     refresh();
    //     cancel();

    //     // props.updateParent && props.updateParent();
    //   });
    // } else {
    //   userService.createUser(data).then((res) => {
    //     setSubmitting(false);
    //     if (!res.status) {
    //       snackbar("User creation failed", { variant: "error" });
    //       return;
    //     }

    //     snackbar("User created", { variant: "success" });
    //     refresh();
    //     cancel();

    //     // props.updateParent && props.updateParent();
    //   });
    // }
  };

  useEffect(() => {
    if (isEdit && user) {
      setFormValues({
        name: user.name,
        email: user.email,
        mobile: user.mobile,
      });
      setFormValid(true);
    }
  }, [isEdit, user]);

  useEffect(() => {
    if (
      formValues.nameError ||
      formValues.emailError ||
      formValues.mobileError ||
      formValues.passwordError ||
      formValues.confirmPasswordError
    ) {
      setFormValid(false);
      return;
    }

    if (
      !formValues.name ||
      !formValues.email ||
      !formValues.mobile ||
      !formValues.password ||
      !formValues.confirmPassword
    ) {
      return;
    }

    setFormValid(true);
  }, [formValues]);

  return (
    <Box>
      <Box
        sx={{
          textAlign: "left",
          display: "flex",
          alignItems: "center",
          minHeight: "49px",
          marginBottom: "30px",
        }}
      >
        <Typography
          textTransform={"capitalize"}
          width={"fit-content"}
          fontSize={theme.fontSizes.h2}
          color="#0E1C3F"
          display="inline"
          sx={{
            cursor: "pointer",
            "&:hover": {
              textDecoration: "underline",
            },
          }}
          onClick={cancel}
        >
          {getTitle(location)}
        </Typography>
        <BiChevronRight
          style={{
            marginLeft: "8px",
            marginRight: "8px",
            fontSize: "22px",
          }}
        />
        <Typography
          textTransform={"capitalize"}
          width={"fit-content"}
          fontSize={theme.fontSizes.h2}
          color="#0E1C3F"
          display="inline"
        >
          {isEdit ? "Edit" : "Add"} {title}
        </Typography>
      </Box>
      <Box>
        <Grid container>
          <Grid xs={8}>
            <Box
              sx={{
                textAlign: "left",
                pb: "30px",
              }}
            >
              <Typography
                textTransform={"capitalize"}
                width={"fit-content"}
                fontSize={theme.fontSizes.h2}
                color={theme.colors?.primary.default}
                display="inline"
              >
                {isEdit ? "Edit" : "Add"} User
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                pb: "35px",
              }}
            >
              
              <Box>
                {avatar ? (
                  <Avatar
                    variant="rounded"
                    src={avatar}
                    sx={{
                      bgcolor: "#EAEEF6",
                      borderRadius: "10px",
                      color: "#EB568F",
                      width: 77,
                      height: 77,
                    }}
                  ></Avatar>
                ) : (
                  <Avatar
                    variant="rounded"
                    sx={{
                      bgcolor: "#EAEEF6",
                      color: "#34C38F",
                      borderRadius: "10px",
                      width: 77,
                      height: 77,
                    }}
                  >
                    <BiUser fontSize={36} />
                  </Avatar>
                )}
              </Box>
              
              <Typography
                sx={{
                  pl: "25px",
                  color: theme.colors?.primary.default,
                  cursor: "pointer",
                }}
                onClick={handleFileButtonClick}
              >
                Upload Avatar
              </Typography>
              <Grid item xs={6}>
  <Select
    value={status}
   // onChange={(e) => setSelectedRole(e.target.value)}
    onChange={(e) => setStatus(e.target.value)}
    sx={{width: "8rem", height: "2rem"}}
    variant="outlined"
    placeholder="Status"
  >
    <MenuItem value={USER_ROLE}>Active</MenuItem>
    <MenuItem value="admin">Inactive</MenuItem>
    {/* Add other role options as needed */}
  </Select>
</Grid>
              <input
                id="myInput"
                type="file"
                ref={fileInputRef}
                style={{ display: "none" }}
                onChange={handleFileSelected}
              />
            </Box>
            


            <Grid container spacing={3}>
              <Grid item xs={6}>
                <TextField
                  value={formValues.name}
                  onChange={(e) => {
                    setFormValues({
                      ...formValues,
                      name: e.target.value,
                      nameError: "",
                    });
                  }}
                  helperText={formValues.nameError}
                  error={!!formValues.nameError}
                  onBlur={() => {
                    if (!formValues.name) {
                      setFormValues({ ...formValues, nameError: "Required" });
                    }
                  }}
                  placeholder="Name"
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  type="email"
                  value={formValues.email}
                  onChange={(e) => {
                    setFormValues({
                      ...formValues,
                      email: e.target.value,
                      emailError: "",
                    });
                  }}
                  helperText={formValues.emailError}
                  error={!!formValues.emailError}
                  onBlur={() => {
                    if (!formValues.email) {
                      setFormValues({ ...formValues, emailError: "Required" });
                    }
                  }}
                  placeholder="Email"
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  value={formValues.mobile}
                  onChange={(e) => {
                    setFormValues({
                      ...formValues,
                      mobile: e.target.value,
                      mobileError: "",
                    });
                  }}
                  helperText={formValues.mobileError}
                  error={!!formValues.mobileError}
                  onBlur={() => {
                    if (!formValues.mobile) {
                      setFormValues({ ...formValues, mobileError: "Required" });
                    }
                  }}
                  placeholder="Mobile"
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              
                <>
                  <Grid item xs={6}>
                    <TextField
                      type="password"
                      value={formValues.password}
                      error={
                        invalidPassword || !!formValues.confirmPasswordError
                      }
                      onChange={(e) => {
                        validatePassword(e.target.value);
                        setFormValues({
                          ...formValues,
                          password: e.target.value,
                          passwordError: "",
                        });
                      }}
                      helperText={formValues.passwordError}
                      onBlur={() => {
                        if (!formValues.password) {
                          setFormValues({
                            ...formValues,
                            passwordError: "Required",
                          });
                        }
                      }}
                      placeholder="Password"
                      variant="outlined"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      type="password"
                      value={formValues.confirmPassword}
                      error={
                        invalidPassword || !!formValues.confirmPasswordError
                      }
                      onChange={(e) => {
                        validatePassword(e.target.value);
                        let errText = "";
                        if (formValues.password !== e.target.value) {
                          errText = "Password does not match";
                        }

                        setFormValues({
                          ...formValues,
                          confirmPassword: e.target.value,
                          confirmPasswordError: errText,
                        });
                      }}
                      helperText={formValues.confirmPasswordError}
                      onBlur={() => {
                        if (!formValues.confirmPassword) {
                          setFormValues({ 
                            ...formValues,
                            confirmPasswordError: "Required",
                          });
                        }
                      }}
                      placeholder="Re Enter Password"
                      variant="outlined"
                      fullWidth
                    />
                  </Grid>
                  
                  {invalidPassword ? (
                    <Grid item xs={12}>
                      <Typography
                        sx={{
                          color: "red",
                          textAlign: "left",
                        }}
                      >
                        Password must contain at least one number and one
                        uppercase and lowercase letter, one special character
                        and at least 8 or more characters
                      </Typography>
                    </Grid>
                  ) : (
                    ""
                  )}
                </>
              
            </Grid>
  
            
                    
            <Box
              sx={{
                pt: "24px",
                textAlign: "left",
              }}
            >
              <CustomButton
                sx={{
                  pl: "40px",
                  pr: "40px",
                }}
                disabled={!isFormValid || submitting}
                onClick={handleSubmit}
              >
                {submitting ? "Submitting" : "Submit"}
              </CustomButton>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default AddUser;