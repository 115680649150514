import { clearSessionStorage, setAuthStorage } from "./../utils/storage-utils";
import axios, { AxiosRequestConfig, InternalAxiosRequestConfig } from "axios";

import { clearStorage, getAuthStorage } from "../utils/storage-utils";
import auth from "./auth-service";
import { apiConfig } from "../config/api-config";
// import record from "./records-service";
import user from "./user-service";
import course from "./course-service";
import exam from "./exam-service";
import question from "./question-service";
import leader from "./leader-service";
import dashboard from "./dashboard-service";

const httpClient = axios.create(apiConfig);

httpClient.interceptors.request.use(
  (config: InternalAxiosRequestConfig<AxiosRequestConfig>) => {
    if (config.headers) {
      config.headers["x-domain-host"] = window.location.hostname || "";
    }

    const auth = getAuthStorage();
    if (auth && config.headers) {
      config.headers.Authorization = `Bearer ${auth.token}`;
    }

    if (!config.params) {
      config.params = {};
    }

    config.params["cache-invalidate"] = Date.now();
    return config;
  }
);

export const logout = () => {
  clearStorage();
  clearSessionStorage();
  window.location.href = `/sign-in`;
};

httpClient.interceptors.response.use(
  (response) => {
    return response;
  },
  async (err) => {
    const originalRequest = err.config;
    if (err.message === "Network Error") {
      return;
    }

    if (err.request.responseURL.includes("/api/sign-in")) {
      return;
    }

    if (
      err.response.status === (401||403) &&
      err.config &&
      !err.config.__isRetryRequest
    ) {
      originalRequest._retry = true;
      try {
        const authData = getAuthStorage();
        const refreshToken = authData?.refreshToken;
        const response = await httpClient.post("/refresh-token", {
          refreshToken,
        });
        if (response.data.accessToken) {
          const { accessToken } = response.data;
          setAuthStorage({ ...authData, token:accessToken });
          originalRequest.headers.Authorization = `Bearer ${accessToken}`;
        }
        // Retry the original request with the new token
        return axios(originalRequest);
      } catch (error) {
         
        logout();
        return;
      }
    }

    // if (
    //   err.response.status === 403 &&
    //   err.config &&
    //   !err.config.__isRetryRequest
    // ) {
    //   logout();
    //   return;
    // }

    throw err;
  }
);

const authService = auth(httpClient);
const userService = user(httpClient);
const courseService = course(httpClient);
const examService = exam(httpClient);
const questionService = question(httpClient);
const leaderService = leader(httpClient);
const dashboardService = dashboard(httpClient);

// const reportService = report(httpClient);

export {
  authService,
  userService,
  courseService,
  examService,
  questionService,
  leaderService,
  dashboardService,
};
