import { Button, styled, useTheme } from "@mui/material";

const CustomButton = styled(Button)((props) => {
  const theme = useTheme();
  return {
    borderRadius: "4px",
    // fontWeight: "bold !important",
    height: "43px",
    // textTransform: "uppercase",
    alignItems: "center",
    backgroundColor: theme.colors?.primaryButtons.default,
    color: theme.palette.common.white,
    minWidth: 100,
    "&:hover": {
      background: `#EB568F`,
      color: "white !important",
    },
  };
});

export default CustomButton;
