import { AxiosInstance, AxiosResponse } from "axios";
import { IAPIResponse, IEnquiry, IPerformance, IUser } from "../interfaces";

interface UserService {
  createAdmin: (data: {}) => Promise<IAPIResponse<IUser[]>>;
  updateUser: (data: {}) => Promise<IAPIResponse>;
  courseAccess: (data: {}) => Promise<IAPIResponse>;
  courseAccessRemove: (data: {}) => Promise<IAPIResponse>;
  getUsers: () => Promise<IAPIResponse<IUser[]>>;
  getAllUsers: (
    
  ) => Promise<IAPIResponse<IUser[]>>;
  getAllAdmin: (
    page: number,
    rowsPerPage: number
  ) => Promise<IAPIResponse<IUser[]>>;
  searchUsers: (
    page: number,
    rowsPerPage: number,
    query: string
  ) => Promise<IAPIResponse<IUser[]>>;
  courseEnquiries: (
    page: number,
    rowsPerPage: number|boolean,
    query: string
  ) => Promise<IAPIResponse<IEnquiry[]>>;
  searchAdmins: (
    page: number,
    rowsPerPage: number,
    query: string
  ) => Promise<IAPIResponse<IUser[]>>;
  // getAdmins: () => Promise<IAPIResponse<IUser[]>>;
  // getSuperAdmins: () => Promise<IAPIResponse<IUser[]>>;
  deleteUser: (id: string) => Promise<IAPIResponse>;
  blockUser: (id: string) => Promise<IAPIResponse>;
  performanceOfUser: (id: string) => Promise<IAPIResponse<IPerformance[]>>;
  getSingle: (id: string) => Promise<IAPIResponse<IUser | null>>;
}

const userService = (httpClient: AxiosInstance): UserService => {
  return {
    createAdmin: async (data): Promise<IAPIResponse<IUser[]>> => {
      try {
        const res: AxiosResponse<IAPIResponse<IUser[]>> = await httpClient.post(
          "/create-admin",
          data
        );
        return res.data;
      } catch (error) {
        return { status: false, message: "This email Already use" };
      }
    },
    courseAccess: async (data): Promise<IAPIResponse> => {
      try {
        const res: AxiosResponse<IAPIResponse> = await httpClient.post(
          "/course-access",
          data
        );
        return res.data;
      } catch (error) {
        return { status: false, message: "User creation failed" };
      }
    },
    courseAccessRemove: async (data): Promise<IAPIResponse> => {
      try {
        const res: AxiosResponse<IAPIResponse> = await httpClient.post(
          "/course-access-remove",
          data
        );
        return res.data;
      } catch (error) {
        return { status: false, message: "User creation failed" };
      }
    },
    performanceOfUser: async (
      userId
    ): Promise<IAPIResponse<IPerformance[]>> => {
      try {
        const res: AxiosResponse<IAPIResponse<IPerformance[]>> =
          await httpClient.get(`/get-user-performance?userId=${userId}`);
        return res.data;
      } catch (error) {
        return { status: false, message: "Failed to fetch" };
      }
    },
    getUsers: async (): Promise<IAPIResponse<IUser[]>> => {
      try {
        const res: AxiosResponse<IAPIResponse<IUser[]>> = await httpClient.get(
          "/users"
        );
        return res.data;
      } catch (error) {
        return { status: false, message: "Failed to fetch users" };
      }
    },
    getAllUsers: async ( ): Promise<IAPIResponse<IUser[]>> => {
      try {
        const res: AxiosResponse<IAPIResponse<IUser[]>> = await httpClient.get(
          `/all-users`
        );
        return res.data;
      } catch (error) {
        return { status: false, message: "Failed to fetch users" };
      }
    },
    searchUsers: async (
      page,
      rowsPerPage,
      query
    ): Promise<IAPIResponse<IUser[]>> => {
      try {
        const res: AxiosResponse<IAPIResponse<IUser[]>> = await httpClient.get(
          `/search-users?page=${page}&pageSize=${rowsPerPage}&query=${query}`
        );
        return res.data;
      } catch (error) {
        return { status: false, message: "Failed to fetch users" };
      }
    },
     courseEnquiries: async (
      page,
      rowsPerPage,
      query
    ): Promise<IAPIResponse<IEnquiry[]>> => {
      try {
        const res: AxiosResponse<IAPIResponse<IEnquiry[]>> = await httpClient.get(
          `/get-course-enquiries?page=${page}&pageSize=${rowsPerPage}&query=${query}`
        );
        return res.data;
      } catch (error) {
        return { status: false, message: "Failed to fetch users" };
      }
    },
    searchAdmins: async (
      page,
      rowsPerPage,
      query
    ): Promise<IAPIResponse<IUser[]>> => {
      try {
        const res: AxiosResponse<IAPIResponse<IUser[]>> = await httpClient.get(
          `/search-admins?page=${page}&pageSize=${rowsPerPage}&query=${query}`
        );
        return res.data;
      } catch (error) {
        return { status: false, message: "Failed to fetch users" };
      }
    },
    getAllAdmin: async (page, rowsPerPage): Promise<IAPIResponse<IUser[]>> => {
      try {
        const res: AxiosResponse<IAPIResponse<IUser[]>> = await httpClient.get(
          `/get-all-admin?page=${page}&pageSize=${rowsPerPage}`
        );
        return res.data;
      } catch (error) {
        return { status: false, message: "Failed to fetch users" };
      }
    },

    deleteUser: async (id): Promise<IAPIResponse> => {
      try {
        const res: AxiosResponse<IAPIResponse> = await httpClient.delete(
          `/delete?userId=${id}`
        );
        return res.data;
      } catch (error) {
        return { status: false, message: "User deletion failed" };
      }
    },
    blockUser: async (id): Promise<IAPIResponse> => {
      try {
        const res: AxiosResponse<IAPIResponse> = await httpClient.post(
          `/block-user`,
          { userId: id }
        );
        return res.data;
      } catch (error) {
        return { status: false, message: "User deletion failed" };
      }
    },
    getSingle: async (id): Promise<IAPIResponse<IUser | null>> => {
      try {
        const res: AxiosResponse<IAPIResponse<IUser | null>> =
          await httpClient.get(`/user/${id}`);
        return res.data;
      } catch (error) {
        return { status: false, message: "Failed to fetch user" };
      }
    },
    updateUser: async (data): Promise<IAPIResponse> => {
      try {
        const res: AxiosResponse<IAPIResponse> = await httpClient.post(
          `/update-user`,
          data
        );
        return res.data;
      } catch (error) {
        return { status: false, message: "User update failed" };
      }
    },
  };
};

export default userService;
