import { AxiosInstance, AxiosResponse } from "axios";
import { IAPIResponse, IQuestion, IReport } from "../interfaces";

interface QuestionService {
  createQuestion: (data: {}) => Promise<IAPIResponse>;
  updateQuestion: (data: {}, id: string) => Promise<IAPIResponse>;
  reportResponse: (data: {}) => Promise<IAPIResponse>;
  getAllReportedQuestions: (
    page: number,
    rowsPerPage: number
  ) => Promise<IAPIResponse<IReport[]>>;
  searchReportedQuestions: (
    page: number,
    rowsPerPage: number,
    query: string
  ) => Promise<IAPIResponse<IReport[]>>;
  getOneQuestion: (data: {}) => Promise<IAPIResponse<IQuestion>>;
  deleteQuestion: (data) => Promise<IAPIResponse>;
}

const questionService = (httpClient: AxiosInstance): QuestionService => {
  return {
    createQuestion: async (data): Promise<IAPIResponse> => {
      // data ={question:'question name', answers=[Normal,Abnormal],
      // radAnswer:"Abnormal",radReason:"reason",examId,
      // longAnswer:{Observations:"",Management:"",Interpretation:"",Differential:"",Diagnosis:""}};
      try {
        const res: AxiosResponse<IAPIResponse> = await httpClient.post(
          "/question/create-question",
          data,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        return res.data;
      } catch (error) {
        return { status: false, message: " Question creation failed" };
      }
    },
    updateQuestion: async (data): Promise<IAPIResponse> => {
      // data ={questionId,question:'question name', answers=[Normal,Abnormal],
      // radAnswer:"Abnormal",radReason:"reason",examId,
      // longAnswer:{Observations:"",Management:"",Interpretation:"",Differential:"",Diagnosis:""}};
      try {
        const res: AxiosResponse<IAPIResponse> = await httpClient.put(
          `/question/update-question`,
          data
        );
        return res.data;
      } catch (error) {
        return { status: false, message: " Question update failed" };
      }
    },
    reportResponse: async (data): Promise<IAPIResponse> => {
      // data ={questionId,resultId,adminResponse}

      try {
        const res: AxiosResponse<IAPIResponse> = await httpClient.post(
          `/result/report-response`,
          data
        );
        return res.data;
      } catch (error) {
        return { status: false, message: " Question update failed" };
      }
    },
    deleteQuestion: async (data): Promise<IAPIResponse> => {
      try {
        const res: AxiosResponse<IAPIResponse> = await httpClient.post(
          `/question/delete-question`,
          data
        );
        return res.data;
      } catch (error) {
        return { status: false, message: " Question deletion failed" };
      }
    },

    getAllReportedQuestions: async (
      page,
      rowsPerPage
    ): Promise<IAPIResponse<IReport[]>> => {
      //  response =[{userName,course,exam,questionType,reportReason,adminResponse,resultId,questionId,userId,question={}}]
      try {
        const res: AxiosResponse<IAPIResponse<IReport[]>> =
          await httpClient.get(
            `/question/reported/get-all?page=${page}&pageSize=${rowsPerPage}`
          );
        return res.data;
      } catch (error) {
        return { status: false, message: "Failed to fetch users" };
      }
    },
    searchReportedQuestions: async (
      page,
      rowsPerPage,
      query
    ): Promise<IAPIResponse<IReport[]>> => {
      //  response =[{userName,course,exam,questionType,reportReason,adminResponse,resultId,questionId,userId,question={}}]
      try {
        const res: AxiosResponse<IAPIResponse<IReport[]>> =
          await httpClient.get(
            `/question/reported/search?page=${page}&pageSize=${rowsPerPage}&query=${query}`
          );
        return res.data;
      } catch (error) {
        return { status: false, message: "Failed to fetch users" };
      }
    },
    getOneQuestion: async (data): Promise<IAPIResponse<IQuestion>> => {
      //  data ={questionId}
      try {
        const res: AxiosResponse<IAPIResponse<IQuestion>> =
          await httpClient.post("/question/get-one-question", data);
        return res.data;
      } catch (error) {
        return { status: false, message: "Failed to fetch users" };
      }
    },
  };
};

export default questionService;
