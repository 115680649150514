import {
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Modal,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import React, { FC } from "react";
import { BiEditAlt, BiLogOutCircle } from "react-icons/bi";
import { logout } from "../../service";
import { validateToken } from "../../utils/auth-utils";
import ProfileAvatar from "../common/profile-avatar/ProfileAvatar";
import { useNavigate } from "react-router-dom";

interface IProps {
  open: boolean;
  onClose: VoidFunction;
}

const ProfileDialogue: FC<IProps> = ({ open, onClose }) => {
  const style = {
    position: "absolute" as "absolute",
    top: 75,
    right: 44,
    width: 260,
    // height: 200,
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: 2,
  };
  const session = validateToken();
  const navigate = useNavigate();

  const modalOptions = [
    {
      title: "Edit My Profile",
      icon: <BiEditAlt fontSize={20} />,
      action: () => {
        navigate("/edit-profile");
      },
    },
    {
      title: "Log out",
      icon: <BiLogOutCircle fontSize={20} transform={"rotate(180deg)"} />,
      action: logout,
    },
  ];
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{
        ".MuiModal-backdrop": {
          background: "transparent",
        },
      }}
    >
      <Box sx={style}>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          gap={1}
          height={140}
          pt={3}
        >
          <Box>
            <ProfileAvatar size="55px" />
          </Box>
          <Box>
            <Typography
              sx={{
                textTransform: "capitalize",
              }}
            >
              {session.name || "Username"}
            </Typography>
          </Box>
          <Box>
            <Typography>{session.email}</Typography>
          </Box>
        </Box>
        <Box>
          <List>
            {modalOptions.map((d, index) => (
              <ListItem key={index} disablePadding sx={{ background: "white" }}>
                <ListItemButton
                  sx={{ display: "flex", justifyContent: "center", gap: 0 }}
                  onClick={() => {
                    d.action && d.action();
                    onClose();
                  }}
                >
                  <ListItemIcon
                    sx={{ display: "flex", justifyContent: "center" }}
                  >
                    {d.icon}
                  </ListItemIcon>
                  <ListItemText primary={d.title} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Box>
      </Box>
    </Modal>
  );
};

export default ProfileDialogue;
