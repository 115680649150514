import { Button, styled} from "@mui/material";

const IconButton = styled(Button)((props) => {
  //const theme = useTheme();
  return {
    borderRadius: "20%",
    fontWeight: "bold !important",
    height: 42,
    aspectRatio: "1/1",
    textTransform: "uppercase",
    alignItems: "center",
    color: "#57617A",
    minWidth: 42,
    backgroundColor:'#F3F4F8',
    '&:hover': {
      background:`#EB568F`,
      color: "white",
   },
  };
});

export default IconButton;
