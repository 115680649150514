import { AxiosInstance, AxiosResponse } from "axios";
import { IAPIResponse, IDashboardData } from "../interfaces";

interface QuestionService {
  getDashboardContent: () => Promise<IAPIResponse<IDashboardData>>;
}

const dashboardService = (httpClient: AxiosInstance): QuestionService => {
  return {
    getDashboardContent: async (): Promise<IAPIResponse<IDashboardData>> => {
      try {
        const res: AxiosResponse<IAPIResponse<IDashboardData>> =
          await httpClient.get("/dashboard/dashboard-data");
        return res.data;
      } catch (error) {
        return { status: false, message: "Failed to fetch users" };
      }
    },
  };
};
export default dashboardService;
