import * as React from "react";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "../../components/common/custom-buttons/IconButton";
import { BiBell } from "react-icons/bi";
import { useNavigate, useParams } from "react-router-dom";
import ProfileDialogue from "../dialogue/ProfileDialogue";
import ProfileAvatar from "../common/profile-avatar/ProfileAvatar";

const TopBar = () => {
  //const theme = useTheme();
  const navigate = useNavigate();
  const { key } = useParams();
  const [profileOpen, setProfileOpen] = React.useState(false);
  const [searchKey, setSearchKey] = React.useState(key || "");
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setProfileOpen(true);
  };

  const handleClose = () => {
    setProfileOpen(false);
  };

  // const handleLogout = () => {
  //   clearSessionStorage();
  //   clearStorage();
  //   navigate("/sign-in");
  //   // handleClick();
  // };

  React.useEffect(() => {
    if (!key && searchKey) {
      setSearchKey("");
    }
  }, [key, searchKey]);

  return (
    <>
      <Toolbar sx={{ height: 73 }}>
        <Box sx={{ flexGrow: 1 }} />
        <Box display="flex" gap={1.9}>
          <IconButton
            sx={{ height: 45, minWidth: 45 }}
            aria-label="account of current user"
            aria-haspopup="true"
            color="info"
            onClick={() => {
              navigate("/notification");
            }}
          >
            <BiBell fontSize={24} />
          </IconButton>

          <IconButton
            sx={{ height: 45, minWidth: 45 }}
            aria-label="account of current user"
            aria-haspopup="true"
            color="info"
            onClick={handleClick}
          >
            <ProfileAvatar size="45px" />
          </IconButton>
        </Box>
      </Toolbar>
      <ProfileDialogue
        open={profileOpen}
        onClose={() => {
          handleClose();
        }}
      />
    </>
  );
};

export default TopBar;
