import { Typography, useTheme } from "@mui/material";
import { apiConfig } from "../../../config/api-config";
import { validateToken } from "../../../utils/auth-utils";
import { useState } from "react";

const ProfileAvatar = ({ size = "55px" }) => {
    const session = validateToken();
    const theme = useTheme();
    const [isProfileImageAvailable, setIsProfileImageAvailable] = useState(true);
    return (
        <>
            {isProfileImageAvailable ? (
              <img
                style={{
                  height: size,
                  width: size,
                  objectFit: "cover",
                  borderRadius: "13px"
                }}
                src={`${apiConfig.serverURL}${session.image}`}
                onError={(_target: any) => {
                  setIsProfileImageAvailable(false);
                }}
                alt="profile"
              />
            ) : (
              <Typography sx={{
                height: size,
                width: size,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                background: theme.colors?.colorSix.default,
                borderRadius: "13px",
                textTransform: "capitalize",
                fontWeight: "bold",
                fontSize: "24px",
                color: theme.colors?.primary.default,
                '&:hover': {
  
                  color: '#fff',
                  background: `#EB568F`
                
                }
              }}>
                {session.name?.charAt(0)}
              </Typography>
            )}
          </>
    );
};

export default ProfileAvatar;