import { AxiosInstance, AxiosResponse } from "axios";
import { IAPIResponse, ILeaderData } from "../interfaces";

interface QuestionService {

  getLeaderData: (data:{}) => Promise<IAPIResponse<ILeaderData[]>>;
  
}

const leaderService = (httpClient: AxiosInstance): QuestionService => {
  return {
   
     
    getLeaderData: async (data): Promise<IAPIResponse<ILeaderData[]>> => {
    //  data ={courseId}
    //   response = {courseName,score,userName,userEmail}
      try {
        const res: AxiosResponse<IAPIResponse<ILeaderData[]>> =
          await httpClient.post("/course/leader-board-data", data);
        return res.data;
      } catch (error) {
        return { status: false, message: "Failed to fetch users" };
      }
    },
  };
};

export default leaderService;
