import { Box } from "@mui/material";
import React, { FC, ReactNode, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getAuthStorage } from "../../utils/storage-utils";
import Drawer from "./Drawer";
import Loader from "../Loader";

interface TemplateProps {
  children: ReactNode;
}

const Template: FC<TemplateProps> = ({ children }) => {
  //const theme = useTheme();
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const session = getAuthStorage();
    if (!session || !session.token) {
      navigate("/sign-in");
      return;
    }

    if (location.pathname === "" || location.pathname === "/") {
      navigate("/dashboard");
    }
    setLoading(false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) {
    return <Loader />;
  }
  return (
    <Box className="full-height">
      <Drawer>
        <Box className="full-height" paddingTop={"21px"}>
          {children}
        </Box>
      </Drawer>
    </Box>
  );
};

export default Template;
