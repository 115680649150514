import { BiAbacus, BiFolderPlus } from "react-icons/bi";
import { TbReportAnalytics } from "react-icons/tb";
import { Location } from "react-router-dom";
import { ISideBarOption } from "../interfaces";
import { MdDashboard } from "react-icons/md";
import { FaUser } from "react-icons/fa";
export const sideBarOptions: ISideBarOption[] = [
  // { title: "Dashboard", icon: BiHomeAlt, url: "/dashboard", name: "dashboard" },

  {
    title: "Dashboard",
    icon: MdDashboard,
    url: "/dashboard",
    name: "dashboard",
  },
  {
    title: "User Management",
    icon: FaUser,
    url: "/usermanagement",
    name: "usermanagement",
  },
  {
    title: "Admin Management",
    icon: FaUser,
    url: "/adminmanagement",
    name: "adminmanagement",
  },
  {
    title: "Course Creation",
    icon: BiFolderPlus,
    url: "/coursecreation",
    name: "coursecreation",
  },
  {
    title: "Quizzes",
    icon: BiAbacus,
    url: "/quizzes",
    name: "quizzes",
  },
  // {
  //   title: "Question Bank",
  //   icon: BsFillPatchQuestionFill,
  //   url: "/questionbank",
  //   name: "questionbank",
  // },
  // {
  //   title: "Questions Creation",
  //   icon: BsClipboardPlusFill,
  //   url: "/questionscreation",
  //   name: "questionscreation",
  // },

  {
    title: "Reported Question",
    icon: TbReportAnalytics,
    url: "/reportedquestion",
    name: "reports",
  },
  {
    title: "Leader Board",
    icon: TbReportAnalytics,
    url: "/LeaderBoard",
    name: "LeaderBoard",
  },
  {
    title: "Course Enquiry",
    icon: TbReportAnalytics,
    url: "/course-enquiry",
    name: "courseEnquiry",
  },


  // { title: "Files", icon: BiFolder, url: "/files", name: "files" },
  // { title: "Shared", icon: BiShareAlt, url: "/shared", name: "shared" },
  // { title: "My profile", icon: BiUser, url: "/profile", name: "profile" },
  // { title: "Recent", icon: BiHistory, url: "/recent", name: "recent" },
  // { title: "Users", icon: BiUser, url: "/users", name: "users" },
  // { title: "Admins", icon: BiUser, url: "/admins", name: "admins" },
  // {
  //   title: "Super admins",
  //   icon: BiUser,
  //   url: "/super-admins",
  //   name: "superAdmins",
  // },
  // {
  //   title: "Activities",
  //   icon: BiHistory,
  //   url: "/activities",
  //   name: "activities",
  // },
  // { title: "Trash", icon: BiTrash, url: "/trash", name: "trash" },
];

export const superAdminMenuItems = [
  "dashboard",
  "quizzes",
  "questionbank",
  "questionscreation",
  "coursecreation",
  "usermanagement",
  "adminmanagement",
  "courseEnquiry",
  "LeaderBoard",

  "reports",
  "files",
  "users",
  "admins",
  "superAdmins",
  "activities",
  "trash",
];

export const getTitle = (location: Location) =>
  sideBarOptions.find((d) =>
    location.pathname.toLowerCase().includes(d.url.toLowerCase())
  )?.title || "dashboard";

export const ROLES = {
  SUPER_ADMIN: "super_admin",
};

export const ROLE_TYPES = {
  super_admin: "Super Admin",
};

export const SUPER_ADMIN_ROLE = "super_admin";

export const USER_ROLE = "user";

export const RECORD_PERMISSIONS_TYPES = {
  VIEW: "view",
  EDIT: "edit",
  OWNER: "owner",
};

export const RECORD_PERMISSIONS = [
  RECORD_PERMISSIONS_TYPES.VIEW,
  RECORD_PERMISSIONS_TYPES.EDIT,
];

export const imageExtensions = ["jpg", "jpeg", "png", "gif", "webp", "svg"];
