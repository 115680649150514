import { AxiosInstance, AxiosResponse } from "axios";
import { IAPIResponse, IExam, IQuiz, IResult } from "../interfaces";

interface ExamService {
  createExam: (data: {}) => Promise<IAPIResponse>;
  updateExam: (data: {}) => Promise<IAPIResponse>;
  cloneExam: (data: {}) => Promise<IAPIResponse>;
  getAllQuizzes: (page:number,rowsPerPage:number) => Promise<IAPIResponse<IQuiz[]>>;
  searchExams: (page:number,rowsPerPage:number,query: string) => Promise<IAPIResponse<IQuiz[]>>;
  openCourse: (data: {}) => Promise<IAPIResponse<IExam[]>>;
  openFolder: (data: {}) => Promise<IAPIResponse<IExam[]>>;
  getFoldersBasedOnCourse: (data: {}) => Promise<IAPIResponse<IExam[]>>;
  viewResultOfExam: (data: {}) => Promise<IAPIResponse<IResult[]>>;
  deleteExam: (id: string) => Promise<IAPIResponse>;
}

const examService = (httpClient: AxiosInstance): ExamService => {
  return {
    createExam: async (data): Promise<IAPIResponse> => {
      try {
        const res: AxiosResponse<IAPIResponse> = await httpClient.post(
          "/exam/create-exam",
          data
        );
        return res.data;
      } catch (error) {
        return { status: false, message: " Exam creation failed" };
      }
    },

    getAllQuizzes: async (page,rowsPerPage): Promise<IAPIResponse<IQuiz[]>> => {
      // for quizzes page
      // response = course,exam ,examId,examType,
      // question=[{question,radAnswer,radReason,longAnswer={Observations:"",Management:"",Interpretation:"",Differential:"",Diagnosis:""},image}]
      try {
        const res: AxiosResponse<IAPIResponse<IQuiz[]>> = await httpClient.get(
         `/exam/get-all-exams?page=${page}&pageSize=${rowsPerPage}`
        );
        return res.data;
      } catch (error) {
        return { status: false, message: "Failed to fetch users" };
      }
    },
    searchExams: async (page,rowsPerPage,query): Promise<IAPIResponse<IQuiz[]>> => {
      // for quizzes page
      // response = course,exam ,examId,examType,
      // question=[{question,radAnswer,radReason,longAnswer={Observations:"",Management:"",Interpretation:"",Differential:"",Diagnosis:""},image}]
      try {
        const res: AxiosResponse<IAPIResponse<IQuiz[]>> = await httpClient.get(
         `/exam/search-exams?page=${page}&pageSize=${rowsPerPage}&query=${query}`
        );
        return res.data;
      } catch (error) {
        return { status: false, message: "Failed to fetch users" };
      }
    },

    openCourse: async (data): Promise<IAPIResponse<IExam[]>> => {
      try {
        const res: AxiosResponse<IAPIResponse<IExam[]>> = await httpClient.post(
          "/exam/open-course",
          data
        );
        return res.data;
      } catch (error) {
        return { status: false, message: "Failed to fetch users" };
      }
    },
    getFoldersBasedOnCourse: async (data): Promise<IAPIResponse<IExam[]>> => {
      //  data = {courseId=""}
      try {
        const res: AxiosResponse<IAPIResponse<IExam[]>> = await httpClient.post(
          "/exam/get-folders-based-on-course",
          data
        );
        return res.data;
      } catch (error) {
        return { status: false, message: "Failed to fetch users" };
      }
    },
    openFolder: async (data): Promise<IAPIResponse<IExam[]>> => {
      //  data= {contentId = ""}
      try {
        const res: AxiosResponse<IAPIResponse<IExam[]>> = await httpClient.post(
          "/exam/open-folder",
          data
        );
        return res.data;
      } catch (error) {
        return { status: false, message: "Failed to fetch users" };
      }
    },
    viewResultOfExam: async (data): Promise<IAPIResponse<IResult[]>> => {
      //  data= {examId = ""}
      // response =[{userName,userId,correct,total,incorrect,notAttended}]
      try {
        const res: AxiosResponse<IAPIResponse<IResult[]>> = await httpClient.post(
          "/result/one-exam-result",
          data
        );
        return res.data;
      } catch (error) {
        return { status: false, message: "Failed to fetch users" };
      }
    },
    deleteExam: async (id): Promise<IAPIResponse> => {
      // Remove folder and exam with this Api ,
      //  please remove all exam before delete folder
      try {
        const res: AxiosResponse<IAPIResponse> = await httpClient.delete(
          `/exam/delete-exam?examId=${id}`
        );
        return res.data;
      } catch (error) {
        return { status: false, message: " Exam deletion failed" };
      }
    },

    updateExam: async (data): Promise<IAPIResponse> => {
      // update or rename
      //  data={examId,title,questionType,durationMinutes}
      try {
        const res: AxiosResponse<IAPIResponse> = await httpClient.put(
          `/exam/update-exam`,
          data
        );
        return res.data;
      } catch (error) {
        return { status: false, message: " Exam update failed" };
      }
    },
    cloneExam: async (data): Promise<IAPIResponse> => {
      // data={examId,title,folderId,courseId}
      //default courseIsParent:false if you need add exam under the course pass as true then folderId is not required
      try {
        const res: AxiosResponse<IAPIResponse> = await httpClient.post(
          `/exam/clone-exam`,
          data
        );
        return res.data;
      } catch (error) {
        return { status: false, message: " Exam update failed" };
      }
    },

   
  };
};

export default examService;
