import { AxiosInstance, AxiosResponse } from "axios";
import { IAPIResponse, ICourse } from "../interfaces";

interface CourseService {
  createCourse: (data: {}) => Promise<IAPIResponse>;
  updateCourse: (data: {}) => Promise<IAPIResponse>;
  getCourses: () => Promise<IAPIResponse<ICourse[]>>;
  deleteCourse: (id: string) => Promise<IAPIResponse>;
  getCourse: (id: string) => Promise<IAPIResponse<ICourse | null>>;
  searchCourses: (
    query: string
  ) => Promise<IAPIResponse<ICourse[]>>
}

const courseService = (httpClient: AxiosInstance): CourseService => {
  return {
    createCourse: async (data): Promise<IAPIResponse> => {
      try {
        const res: AxiosResponse<IAPIResponse> = await httpClient.post(
          "/course/create-course",
          data
        );
        return res.data;
      } catch (error) {
        return { status: false, message: "Course creation failed" };
      }
    },

    getCourses: async (): Promise<IAPIResponse<ICourse[]>> => {
      try {
        const res: AxiosResponse<IAPIResponse<ICourse[]>> =
          await httpClient.get("/course/get-courses-data");
        return res.data;
      } catch (error) {
        return { status: false, message: "Failed to fetch users" };
      }
    },
    searchCourses: async (query): Promise<IAPIResponse<ICourse[]>> => {
      try {
        const res: AxiosResponse<IAPIResponse<ICourse[]>> =
          await httpClient.get(`/course/search-courses?query=${query}`);
        return res.data;
      } catch (error) {
        return { status: false, message: "Failed to fetch users" };
      }
    },

    deleteCourse: async (id): Promise<IAPIResponse> => {
      try {
        const res: AxiosResponse<IAPIResponse> = await httpClient.delete(
          `/course/delete-course?courseId=${id}`
        );
        return res.data;
      } catch (error) {
        return { status: false, message: "Course deletion failed" };
      }
    },
    getCourse: async (id): Promise<IAPIResponse<ICourse | null>> => {
      try {
        const res: AxiosResponse<IAPIResponse<ICourse | null>> =
          await httpClient.get(`/course/${id}`);
        return res.data;
      } catch (error) {
        return { status: false, message: "Failed to fetch course" };
      }
    },
    updateCourse: async (data): Promise<IAPIResponse> => {
      try {
        const res: AxiosResponse<IAPIResponse> = await httpClient.put(
          `/course/update-course`,
          data
        );
        return res.data;
      } catch (error) {
        return { status: false, message: "Course update failed" };
      }
    },
  };
};

export default courseService;
