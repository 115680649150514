/* eslint-disable react-hooks/exhaustive-deps */
import { lazy } from "react";
import { Route, Routes } from "react-router-dom";
import { apiConfig } from "../config/api-config";
import Template from "../components/template/Template";
// import Admins from "../pages/admins/Admins";
import SuperAdmins from "../pages/super-admins/superAdmins";
import Search from "../pages/search/Search";

const UserSignIn = lazy(() => import("../pages/sign-in/SignIn"));
const CourseEnq = lazy(() => import("../pages/courseEnquiry/CourseEnquiry"));

// const AdminSignIn = lazy(() => import("../pages/admin/sign-in/SignIn"));
const FourNotFour = lazy(() => import("../pages/FourNotFour"));
// const Home = lazy(() => import("../pages/home/home"));

const Dashboard = lazy(() => import("../pages/dashboard/Dashboard"));
const Quizzes = lazy(() => import("../pages/quizzes/quizzes"));
const QuestionBank = lazy(() => import("../pages/QuestionBank/QuestionBank"));
//const QuestionsCreation = lazy(() => import("../pages/questionscreation/QuestionsCreation"));
const CourseCreation = lazy(
  () => import("../pages/CourseCreation/CourseCreation")
);
const UserManagement = lazy(
  () => import("../pages/usermanagement/UserManagement")
);
const AdminManagement = lazy(
  () => import("../pages/AdminManagement/AdminManagement")
);
const FlaggedQuestion = lazy(
  () => import("../pages/FlaggedQuestion/FlaggedQuestion")
);
const LeaderBoard = lazy(() => import("../pages/LeaderBoard/LeaderBoard"));

const Reports = lazy(() => import("../pages/reports/Reports"));
const Files = lazy(() => import("../pages/files/Files"));
const Users = lazy(() => import("../pages/users/Users"));
const Activities = lazy(() => import("../pages/activity/Activities"));
const Notifications = lazy(() => import("../pages/notification/Notification"));
const Trash = lazy(() => import("../pages/trash/Trash"));
const CourseDetail = lazy(
  () => import("../components/CourseDetail/CourseDetail")
);
const FolderDetail = lazy(
  () => import("../components/FolderDetail/FolderDetail")
);

const Routers = () => {
  //const auth = useSelector<IRootState, IAuth>(({ auth }) => auth.value);
  // const getRole = () => {
  //   switch (auth.data?.role) {
  //     case Role.ADMIN_ID:
  //       return Role.ADMIN_ID;

  //     case Role.USERS_ID:
  //     default:
  //       return Role.USERS_ID;
  //   }
  // };

  // const getRoutes = useCallback(() => {
  //   const role = getRole();
  //   if (auth.data && role === Role.ADMIN) {
  //     return adminRoutes.map((route) => ({ ...route, role: role }));
  //   }

  //   if (auth.data && auth.data.role === Role.USER) {
  //     return userRoutes.map((route) => ({ ...route, role: Role.USER }));
  //   }

  //   return [];
  // }, [auth]);

  return (
    <>
      <Routes>
        {/* <Route
          path={`${apiConfig.basePath}/`}
          element={
            <Root>
              <PrivateRoute isIndex />
            </Root>
          }
        />

        {getRoutes().map(({ path, component, role }, index) => (
          <Route
            key={index}
            path={`${apiConfig.basePath}${path}`}
            element={
              <Template>
                <PrivateRoute component={component} access={role} />
              </Template>
            }
          />
        ))} */}
        <Route
          path={`${apiConfig.basePath}/sign-in`}
          element={<UserSignIn />}
        />
        <Route
          path={`${apiConfig.basePath}/`}
          element={
            <Template>
              <></>
            </Template>
          }
        />
        <Route
          path={`${apiConfig.basePath}`}
          element={
            <Template>
              <></>
            </Template>
          }
        />

        <Route
          path={`${apiConfig.basePath}/dashboard`}
          element={
            <Template>
              <Dashboard />
            </Template>
          }
        />

        <Route
          path={`${apiConfig.basePath}/quizzes`}
          element={
            <Template>
              <Quizzes />
            </Template>
          }
        />

        <Route
          path={`${apiConfig.basePath}/questionbank`}
          element={
            <Template>
              <QuestionBank />
            </Template>
          }
        />
        {/* <Route
          path={`${apiConfig.basePath}/questionscreation`}
          element={
            <Template>
              <QuestionsCreation />
            </Template>
          }
        /> */}
        <Route
          path={`${apiConfig.basePath}/coursecreation`}
          element={
            <Template>
              <CourseCreation />
            </Template>
          }
        />
        <Route
          path={`${apiConfig.basePath}/coursecreation/CourseDetail`}
          element={
            <Template>
              <CourseDetail />
            </Template>
          }
        />
        <Route
          path={`${apiConfig.basePath}/coursecreation/CourseDetail/FolderDetail`}
          element={
            <Template>
              <FolderDetail />
            </Template>
          }
        />
        <Route
          path={`${apiConfig.basePath}/usermanagement`}
          element={
            <Template>
              <UserManagement />
            </Template>
          }
        />
        <Route
          path={`${apiConfig.basePath}/adminmanagement`}
          element={
            <Template>
              <AdminManagement />
            </Template>
          }
        />
        <Route
          path={`${apiConfig.basePath}/course-enquiry`}
          element={
            <Template>
              <CourseEnq />
            </Template>
          }
        />

        <Route
          path={`${apiConfig.basePath}/reportedquestion`}
          element={
            <Template>
              <Reports />
            </Template>
          }
        />
        <Route
          path={`${apiConfig.basePath}/flaggedquestion`}
          element={
            <Template>
              <FlaggedQuestion />
            </Template>
          }
        />
        
        <Route
          path={`${apiConfig.basePath}/LeaderBoard`}
          element={
            <Template>
              <LeaderBoard />
            </Template>
          }
        />
        <Route
          path={`${apiConfig.basePath}/files`}
          element={
            <Template>
              <Files />
            </Template>
          }
        />
        <Route
          path={`${apiConfig.basePath}/files/:id`}
          element={
            <Template>
              <Files />
            </Template>
          }
        />
        <Route
          path={`${apiConfig.basePath}/files/super-admin/:superAdminId`}
          element={
            <Template>
              <Files />
            </Template>
          }
        />
        <Route
          path={`${apiConfig.basePath}/files/admin/:adminId`}
          element={
            <Template>
              <Files />
            </Template>
          }
        />
        <Route
          path={`${apiConfig.basePath}/files/user/:userId`}
          element={
            <Template>
              <Files />
            </Template>
          }
        />
        <Route
          path={`${apiConfig.basePath}/files/user/:userId/:recordId`}
          element={
            <Template>
              <Files />
            </Template>
          }
        />
        <Route
          path={`${apiConfig.basePath}/users`}
          element={
            <Template>
              <Users />
            </Template>
          }
        />
        {/* <Route
          path={`${apiConfig.basePath}/admins`}
          element={
            <Template>
              <Admins />
            </Template>
          }
        /> */}
        <Route
          path={`${apiConfig.basePath}/super-admins`}
          element={
            <Template>
              <SuperAdmins />
            </Template>
          }
        />
        <Route
          path={`${apiConfig.basePath}/activities`}
          element={
            <Template>
              <Activities />
            </Template>
          }
        />
        <Route
          path={`${apiConfig.basePath}/notification`}
          element={
            <Template>
              <Notifications />
            </Template>
          }
        />
        <Route
          path={`${apiConfig.basePath}/trash`}
          element={
            <Template>
              <Trash />
            </Template>
          }
        />

        <Route
          path={`${apiConfig.basePath}/trash/:id`}
          element={
            <Template>
              <Trash />
            </Template>
          }
        />

        <Route
          path={`${apiConfig.basePath}/search/:key`}
          element={
            <Template>
              <Search />
            </Template>
          }
        />
        <Route path="*" element={<FourNotFour />} />
      </Routes>
    </>
  );
};

export default Routers;
