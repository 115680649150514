/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { IAuth } from "../../interfaces";
import { authService } from "../../service";
import { getAuthStorage } from "../../utils/storage-utils";

const storage = getAuthStorage();

const initialState: IAuth = storage
  ? { status: true, message: "", loading: false, data: storage }
  : { status: false, message: "", loading: false };

export const signInAsync = createAsyncThunk<
  IAuth,
  { email: string; password: string; orgId: string }
>("auth/sign-in", async ({ email, password, orgId }): Promise<IAuth> => {
  try {
    const res = await authService.userSignIn(email, password, orgId);
    return res;
  } catch (err: any) {
    return { status: false, message: err.message as string };
  }
});

export const authSlice = createSlice({
  name: "auth",
  initialState: {
    value: initialState,
  },
  reducers: {
    setAuth: (state, action: PayloadAction<IAuth>) => {
      state.value = { ...action.payload };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(signInAsync.pending, (state) => {
      state.value = { ...initialState, loading: true };
    });
    builder.addCase(signInAsync.fulfilled, (state, action) => {
      state.value = { ...action.payload, loading: false };
    });
  },
});

export const { setAuth } = authSlice.actions;

export default authSlice.reducer;
